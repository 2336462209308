.imageContainer {
  position: relative;
  background: #151515;
  max-height: 650px;
  margin: auto;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    display: block;
    padding-bottom: 66.25%;
  }
  img {
    position: absolute;
    max-width: 100vw;
    //height: 100%;
    width: 100%;
    //top: 0;
    left: 0;
  }
}
.example-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background: white;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
  @media (max-width: 700px) {
    width: 35px;
    height: 35px;
  }
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.customOl {
  display: block;
  margin-left: 20px;
  li {
    margin: 5px 0 5px 0;
  }
}
.customUl {
  display: block;
  margin-left: 20px;
  list-style: inside;
  li {
    margin: 5px 0 5px 0;
  }
}
.post {
  width: 100%;
}
